import React from 'react';
import { css } from '@emotion/core'

const style = css`
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}`;
    
export default ({slug, title}) => (
  <div css={style} style={{width: "80%", margin: "0 auto", paddingBottom: "20px"}}>
    <div className="video-container">
        <iframe src={'https://www.youtube.com/embed/' + slug} frameborder="0" title={title}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
);